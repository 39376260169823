import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class TunnelPaiementService {

  constructor(
    protected http: HttpClient,
    protected networkService: NetworkStatusService,
  ) { }

  async onInit() {
    return 'TunnelPaiement done';
  }

  fetchTunnelEntry(userId: any, gearboxType: any, offerType: any, quantity?: any): Promise<any> {

    let queryString = '';
    if (!!userId && userId !== 'GUEST') {
      queryString = '?user_id=' + userId;
    }
    if (!gearboxType) {
      gearboxType = 'bvm'; // anti-couillons
    }
    if (!offerType) {
      offerType = 'big_pack'; // anti-couillons
    }

    let startOrContinue = '';
    if (offerType != "code_premium") {
      startOrContinue = (queryString === '') ? '?' : '&';
      queryString += startOrContinue + 'gearbox_type=' + gearboxType;
    }
    startOrContinue = (queryString === '') ? '?' : '&';
    queryString += startOrContinue + 'offer_type=' + offerType;

    if (!!quantity) {
      startOrContinue = (queryString === '') ? '?' : '&';
      queryString += startOrContinue + 'quantity=' + quantity;
    }

    const url = `${environment.token_auth_config.apiBase}/v3/tunnel/entry${queryString}`;

    if (this.networkService?.isOffline()) {
      return Promise.resolve([]);
    }

    return this.http.get(url).pipe(
      switchMap(
        async (data: any) => {
          if (!!data && !!data.data) {
            return data.data;
          } else {
            return { errorCode: 'E301', errorMessage: 'fetchTunnelEntry Service data failed', errorOriginal: data }
          }
        }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'fetchTunnelEntry Service failed', errorOriginal: err };
        }
      )
    ).toPromise();
  }

  fetchTunnelNodes(payload: any, gearboxType: any, offerType: any, quantity?: any): Promise<any> {
    if (this.networkService?.isOffline() || (!payload || !payload?.slug)) {
      return of([]).toPromise();
    }

    if (!gearboxType) {
      gearboxType = 'bvm'; // anti-couillons
    }
    if (!offerType) {
      offerType = 'big_pack'; // anti-couillons
    }

    if (!!gearboxType && !!payload) {
      payload.gearbox_type = gearboxType;
    }
    if (!!offerType && !!payload) {
      payload.offer_type = offerType;
    }
    if (!!quantity && payload) {
      payload.quantity = quantity;
    }

    const url = `${environment.token_auth_config.apiBase}/v3/tunnel/nodes/${payload.slug}`;

    return this.http.put(url, payload).pipe(
      switchMap(
        async (data: any) => {
          if (!!data) {
            return data;
          } else {
            return { errorCode: 'E301', errorMessage: 'fetchTunnelEntry Service data failed', errorOriginal: data }
          }
        }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'fetchTunnelEntry Service failed', errorOriginal: err };
        }
      )
    ).toPromise();
  }
}
