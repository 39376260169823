import { EvsRecord } from './evsRecord';

export interface ILesson {
  // accountId: number; // trucs de PO
  // teacherId: number; // trucs de PO
  // startsAt: string; // trucs de PO
  // endsAt: string; // trucs de PO

  id: string;
  type: string;
  created_at: string;
  starts_at: string;
  ends_at: string;
  cancelled_at: string;
  declined_at: string;
  confirmed_at: string;
  status: string;
  amount: number;
  credits: number;
  duration: number;
  driving_exam_id: number;
  teacher: object;
  departure_point: object;
  arrival_point: object;
  automatic: boolean;
  teacher_reported_absent: boolean;
}

export class Lesson extends EvsRecord implements ILesson {

  // accountId: number; // trucs de PO
  // teacherId: number; // trucs de PO
  // startsAt: string; // trucs de PO
  // endsAt: string; // trucs de PO

  id: string;
  type: string;
  created_at: string;
  starts_at: string;
  ends_at: string;
  cancelled_at: string;
  declined_at: string;
  confirmed_at: string;
  status: string;
  amount: number;
  credits: number;
  duration: number;
  driving_exam_id: number;
  teacher: object;
  departure_point: object;
  arrival_point: object;
  automatic: boolean;
  teacher_reported_absent: boolean;

  constructor(
    obj: ILesson
  ) {
    super(obj);
  }
}
