import { TokenService } from './token.service';
import { Injectable } from '@angular/core';
import { Account } from './../models/account';
import { Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';

import { GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { StorageService } from './storage.service';
import { Student } from '@app/app.models';

// ERROR CODE
// E100 => DATA ERROR
// E101: MISSING DATA
// E200 => FUNCTION ERROR
// E201: ERROR CATCHED
// E202: CHECK FAILED
// E203: FAILED PROMISE
// E300 => REQUEST ERROR
// E301: REQUEST FAILED

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(public tokenService: TokenService,
              public http: HttpClient,
              private facebook: Facebook,
              private google: GooglePlus,
              public socialAuthService: SocialAuthService,
              private storageService: StorageService,
              ) {
  }

  testCount = 0;

  handleResponse(response): any {
    let data: any = null;
    const textRes = JSON.stringify(response);
    const parsedData = JSON.parse(textRes);

    if (!!parsedData && !!parsedData.body && !!parsedData.body.data) {
      data = parsedData.body.data;
    } else if (!!parsedData && !!parsedData.data) {
      data = parsedData.data;
    }
    if (!!data) {
      return data;
    }
    return { errorCode: 'E101', errorMessage: 'Handle Response Auth Service missing data in parsedData', errorOriginal: null };
  }

  signIn(mail: string, pwd: string): Observable<any> {
    const account = new Account({
      email: mail,
      password: pwd
    });

    if (!!account) {
      return this.tokenService.signIn(account).pipe(
        switchMap(
          async res => {
            if (!!res && !!res.errorCode) {
              return res;
            }
            if (!!res) {
              const ret = this.handleResponse(res);
              return ret;
            }
            return { errorCode: 'E101', errorMessage: 'Sign In Auth Service missing res', errorOriginal: null };
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E201', errorMessage: 'Sign In Auth Service error catched', errorOriginal: err };
          }
        )
      );
    }
    return of({ errorCode: 'E101', errorMessage: 'Sign In Auth Service missing account', errorOriginal: null });
  }

  register(mail: string, pwd: string, pwdConfirmation: string, addData: any): Observable<any> {
    const account = new Account({
      email: mail,
      password: pwd,
      passwordConfirmation: pwdConfirmation,
      additionalData: addData
    });

    if (!!account) {
      return this.tokenService.register(account).pipe(
        switchMap(
          async data => {
            if (!!data && !!data.errorCode) {
              return data;
            }
            if (!!data) {
              const ret = this.handleResponse(data);
              return ret;
            }
            return { errorCode: 'E101', errorMessage: 'Register Auth Service missing data', errorOriginal: null };
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E201', errorMessage: 'Register Auth Service error catched', errorOriginal: err };
          }
        )
      );
    }
    return of({ errorCode: 'E101', errorMessage: 'Register Auth Service missing account', errorOriginal: null });
  }

  signOut(): Observable<any> {
    return this.tokenService.signOut().pipe(
      switchMap(
        async res => {
          if (!!res) {
            return res;
          }
          return { errorCode: 'E101', errorMessage: 'Sign Out Auth Service missing res', errorOriginal: null };
        }
      ),
      catchError(
        async err => {
          return { errorCode: 'E201', errorMessage: 'Sign Out Auth Service error catched', errorOriginal: err };
        }
      )
    );
  }

  forgotPassword(mail: string): Observable<any> {
    const account = new Account({
      email: mail
    });

    if (!!account) {
      return this.tokenService.resetPassword(account).pipe(
        switchMap(
          async data => {
            if (!!data) {
              return data;
            }
            return { errorCode: 'E101', errorMessage: 'Forgot Password Auth Service missing data', errorOriginal: null };
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E201', errorMessage: 'Forgot Password Auth Service error catched', errorOriginal: err };
          }
        )
      );
    }
    return of({ errorCode: 'E101', errorMessage: 'Forgot Password Auth Service missing account', errorOriginal: null });
  }

  updatePassword(pass: string, passConfirmation: string, oldPass: string): Observable<any> {
    const account = new Account({
      password: pass,
      passwordConfirmation: passConfirmation,
      oldPassword: oldPass
    });

    if (!!account) {
      return this.tokenService.updatePassword(account).pipe(
        switchMap(
          async data => {
            if (!!data) {
              return data;
            }
            return { errorCode: 'E101', errorMessage: 'Update Password Auth Service missing data', errorOriginal: null };
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E201', errorMessage: 'Update Password Auth Service error catched', errorOriginal: err };
          }
        )
      );
    }
    return of({ errorCode: 'E101', errorMessage: 'Update Password Auth Service missing account', errorOriginal: null });
  }


  // comment on se log as maintenant ?
  checkKeys(authParams: any) {
    if (!!authParams) {
      if ((Object.keys(authParams).includes("email") || (Object.keys(authParams).includes("id"))) &&
          Object.keys(authParams).includes("created_at") &&
          Object.keys(authParams).includes("token") &&
          Object.keys(authParams).includes("link")) {
        return true;
      }
    }
    return false;
  }

  autoLog(url: string = null): Observable<any> {
    let authParams = null;
    let link = location.toLocaleString();
    if (!!url) {
      link = url;
    }

    if (!!link) {
      authParams = this.getAuthParams(link);
    } else {
      return of({ errorCode: 'E101', errorMessage: 'Auto Log missing link', errorOriginal: null });
    }
    if (!!authParams && Object.keys(authParams).length !== 0 && this.checkKeys(authParams)) {
      return this.autoLogUser(authParams.id, authParams.email, authParams.token, authParams.created_at, Object.keys(authParams).includes("user_agent") ? authParams.user_agent : null).pipe(
        switchMap(
          async res => {
            if (!!res && !!res.errorCode) {
              return res;
            }
            if (!!res && !!res.msg) {
              if (res.msg.includes('You are successfully logged in as')) {
                const userId = res.msg.slice(res.msg.lastIndexOf('as') + 3, res.msg.length);
                // console.log('user Id => ', userId);
                return userId;
              }
            }
            return { errorCode: 'E101', errorMessage: 'Auto Log Auth Service missing res or auth_data in res', errorOriginal: null };
          }
        ),
        switchMap(
          async validate => {
            if (!!validate && !!validate.errorCode) {
              return validate;
            }
            if (!!validate) {
              if (Object.keys(authParams).includes("debug_id")) {
                return {res: validate, debugId: authParams.debug_id};
              }
              return {res: validate};
            }
            return { errorCode: 'E101', errorMessage: 'Auto Log Auth Service missing validate', errorOriginal: null };
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E201', errorMessage: 'Auto Log Auth Service error catched', errorOriginal: err };
          }
        )
      );
    }
    return of({ errorCode: 'E202', errorMessage: 'Auto Log Auth Service No param found in url', errorOriginal: null });
  }

  getAuthParams(url: string): any {
    const link = url.slice(url.lastIndexOf(`/`) + 1, url.length);
    if (url.split('?')[1] === undefined) {
      return null;
    }
    const args = url.split('?')[1].split('#')[0];
    if (args === undefined) {
      return null;
    } else {
      const obj = {};
      for (const arg of args.split('&')) {
        obj[arg.split('=')[0]] = arg.split('=')[1];
      }
      obj[`link`] = link;
      return obj;
    }
  }

  autoLogUser(id: string, mail: string, tokens: string, createdAt: string, user_agent: string): Observable<any> {
    const url = `${this.tokenService.baseUrl}/session`;
    let data = {};
    if (!!id) {
      data = {
        id: id,
        token: tokens,
        created_at: createdAt,
        user_agent
      };
    } else if (!!mail) {
      data = {
        email: mail,
        token: tokens,
        created_at: createdAt,
        user_agent
      };
    } else {
      return of({ errorCode: 'E101', errorMessage: 'Auto Log User Auth Service missing id and mail', errorOriginal: null });
    }

    return this.http.post(url, data).pipe(
      switchMap(
        async res => {
          if (!!res) {
            // console.log('autolog res => ', res);
            return res;
          }
          return { errorCode: 'E101', errorMessage: 'Auto Log User Auth Service missing res', errorOriginal: null };
        }
      ),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'Auto Log User Request failed', errorOriginal: err };
        }
      )
    )
  }

  async fetchProfile(accountId: string, type: string): Promise<any> {
    // console.log('accountId => ', accountId);
    this.testCount++;
    const url = `${this.tokenService.baseUrl}/${type}/${accountId}`;

    const httpResponse : any = await this.http.get(url).pipe(catchError(
      async err => {
        return { errorCode: 'E301', errorMessage: 'Fetch Profile Auth Service request failed', errorOriginal: err };
      }
    )).toPromise();
    if (!!httpResponse) {
      if (!!httpResponse && !!httpResponse.errorMessage) {
        return httpResponse;
      }
      const data = this.handleResponse(httpResponse);
      if (!!data) {
        data.attributes.id = accountId;
        return data.attributes;
      }
    }
    return { errorCode: 'E101', errorMessage: 'Fetch Profile Auth Service missing httpResponse', errorOriginal: null };
  }

  async isSignedInHard(): Promise<any> {
    // let id = '';
    const isJwtValid = await this.storageService.get('jwtToken');
    const localStudent = new Student(await this.storageService.get('Student'));
    // let student = null;
    // if (id === '' && !!localStudent?.remoteId && localStudent.remoteId !== 'GUEST') {
    //   id = localStudent.remoteId;
    // }

    // if (id !== '') {
    //   student =  await this.fetchProfile(id, 'students');
    // }

    // console.log('id => ', id);
    // console.log('isJwtValid => ', isJwtValid);
    // console.log('student => ', localStudent);
    if (!!isJwtValid && !!localStudent) {
      if (!!isJwtValid?.errorCode) {
        await this.storageService.remove('jwtToken');
        await this.storageService.set('Student', {_remoteId: 'GUEST', _status: 'guest'});
        return { errorCode: 'E201', errorMessage: 'Is Signed In Hard Auth Service error catched', errorOriginal: isJwtValid };
      }
      return localStudent;
    } else {
      await this.storageService.remove('jwtToken');
      await this.storageService.set('Student', {_remoteId: 'GUEST', _status: 'guest'});
      return { errorCode: 'E201', errorMessage: 'Is Signed In Hard Auth Service missing student or jwt', errorOriginal: {isJwtValid, localStudent} };
    }
  }

  async logWithFacebookMobile(studentInfo: {postalCodeId: string, phoneNumber: string, email: string}): Promise<any> {
    const facebook: any = await this.facebook.login(['public_profile', 'email']);
    if (!!facebook && !!facebook.errorMessage) {
      return facebook;
    }
    if (!!facebook?.authResponse?.accessToken) {
      const facebookUserData = await this.facebook.api('/me?fields=id,email,picture.width(720).height(720).as(picture_large)&access_token=' + facebook.authResponse.accessToken, ['public_profile', 'email']);
      const facebookData = await this.fetchProfileWithFacebook(facebook, facebook.authResponse.accessToken, facebookUserData);
      return facebookData;
    }
    return { errorCode: 'E201', errorMessage: 'Log With Facebook Mobile missing facebook or other data in facebook', errorOriginal: null };
  }

  async logWithGoogleMobile(studentInfo: {postalCodeId: string, phoneNumber: string}): Promise<any> {
    // alert('log with google mobile');
    // const logoutGoogle = await this.google.logout();
    // alert('passed logout');
    // alert(`Logout google ${JSON.stringify(logoutGoogle)}`);
    const google = await this.google.login({ 'offline': true, 'webClientId': '24457391814-th3e0qebifu5dirh3d18iladk5qpvfv0.apps.googleusercontent.com', 'postal_code_id': studentInfo.postalCodeId, 'phone': studentInfo.phoneNumber});
    // alert(`google => ${JSON.stringify(google)}`);
    if (!!google && google.errorMessage) {
      return google;
    }
    if (!!google?.accessToken) {
      const googleData = await this.fetchProfileWithGoogle(google, google.accessToken);
      // alert(`google data ${JSON.stringify(googleData)}`);
      return googleData;
    }
    return { errorCode: 'E101', errorMessage: 'Log With Google Mobile missing google or other data in google', errorOriginal: null };
  }

  async logWithFacebookWeb(): Promise<any> {
    const signInData: any = await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).catch(
      err => {
        return { errorCode: 'E203', errorMessage: 'Log With Facebook Web failed promise', errorOriginal: err };
      }
    );
    if (!!signInData && !!signInData.errorMessage) {
      return signInData;
    }
    if (!!signInData) {
      const profile: any = await this.fetchProfileWithFacebook(signInData);
      if (!!profile) {
        return profile;
      }
      return { errorCode: 'E101', errorMessage: 'Log With Facebook Web missing profile', errorOriginal: null };
    }
    return { errorCode: 'E101', errorMessage: 'Log With Facebook Web missing signInData', errorOriginal: null };
  }

  async logWithGoogleWeb(): Promise<any> {
    // console.log('logWithGoogleWeb');
    const signInData: any = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).catch(
      err => {
        return { errorCode: 'E203', errorMessage: 'Log With Google Web failed promise', errorOriginal: err };
      }
    );
    // console.log('signInData => ', signInData);
    if (!!signInData && !!signInData.errorMessage) {
      return signInData;
    }
    if (!!signInData) {
      // console.log('signInData 2 => ', signInData);
      const profile: any = await this.fetchProfileWithGoogle(signInData);
      if (!!profile) {
        return profile;
      }
      return { errorCode: 'E101', errorMessage: 'Log With Google Web missing profile', errorOriginal: null };
    }
    return { errorCode: 'E101', errorMessage: 'Log With Google Web missing signInData', errorOriginal: null };
  }

  async fetchProfileWithGoogle(googleData, accessToken = null): Promise<any> {
    if (!!googleData) {
      const url = `${this.tokenService.baseUrl.slice(0, (this.tokenService.baseUrl.length - 6))}omniauth_callback/google`;
      const data = {
        omniauth: JSON.stringify({
          info: {
            email: googleData.email
          },
          credentials: {
            expires_at: 0,
            token: !!accessToken ? accessToken : googleData.authToken
          },
          extra: {
            id_info: {
              image: googleData.photoUrl,
              email: googleData.email,
            },
          },
          uid: googleData.id,
          provider: googleData.provider,
        })
      };

      const httpResponse: any = await this.http.post(url, data).pipe(catchError(
        async err => {
          return { errorCode: 'E201', errorMessage: 'Fetch Profile With Google request failed', errorOriginal: err };
        }
      )).toPromise();
      if (!!httpResponse && !!httpResponse.errorMessage) {
        return httpResponse;
      }
      if (!!httpResponse?.account_info?.id) {
        const profil = await this.fetchProfile(httpResponse.account_info.id, 'students');
        return { data: profil, id: httpResponse.account_info.id };
      }
      return { errorCode: 'E101', errorMessage: 'Handle Google Omniauth missing httpResponse or account_info in httpResponse ', errorOriginal: null };
    } else {
      return of({ errorCode: 'E101', errorMessage: 'Fetch Profile With Google missing googleData', errorOriginal: null });
    }
  }

  async fetchProfileWithFacebook(facebookData, accessToken = null, facebookUserData = null): Promise<any> {
    if (!!facebookData?.authResponse) {
      facebookData = facebookData.authResponse;
      facebookData.email = facebookUserData?.email;
      facebookData.photoUrl = facebookUserData?.picture_large?.data.url;
    }
    if (!!facebookData) {
      const url = `${this.tokenService.baseUrl.slice(0, (this.tokenService.baseUrl.length - 6))}omniauth_callback/facebook`;
      const data = {
        omniauth: JSON.stringify({
          info: {
            email: facebookData.email
          },
          credentials: {
            expires_at: 0,
            token: !!accessToken ? accessToken : facebookData.authToken
          },
          extra: {
            id_info: {
              image: facebookData.photoUrl,
              email: facebookData.email,
            },
          },
          uid: facebookData.id,
          provider: 'facebook',
        })
      };

      const httpResponse: any = await this.http.post(url, data).pipe(catchError(
        async err => {
          return { errorCode: 'E201', errorMessage: 'Fetch Profile With Facebook request failed', errorOriginal: err };
        }
      )).toPromise();
      if (!!httpResponse && !!httpResponse.errorMessage) {
        return httpResponse;
      }
      if (!!httpResponse?.account_info?.id) {
        const profil = await this.fetchProfile(httpResponse.account_info.id, 'students');
        return { data: profil, id: httpResponse.account_info.id };
      }
      return { errorCode: 'E101', errorMessage: 'Handle Facebook Omniauth missing httpResponse or account_info in httpResponse ', errorOriginal: null };
    } else {
      return of({ errorCode: 'E101', errorMessage: 'Fetch Profile With Facebook missing facebookData', errorOriginal: null });
    }
  }

  async handleFacebookOmniAuth(link: string): Promise<any> {
    if (!!link) {
      const httpResponse = await this.http.get<any>(link, {}).pipe(catchError(
        async err => {
          return { errorCode: 'E201', errorMessage: 'Handle Facebook Omniauth request failed', errorOriginal: err };
        }
      )).toPromise();
      if (!!httpResponse && !!httpResponse.errorMessage) {
        return httpResponse;
      }

      if (!!httpResponse && !!httpResponse.auth_data) {
        return { errorCode: 'E101', errorMessage: 'Handle Facebook Omniauth missing authUserAfterOmniauth', errorOriginal: null };
      }
      return { errorCode: 'E101', errorMessage: 'Handle Facebook Omniauth missing data or auth_data in data ', errorOriginal: null };
    }
    return { errorCode: 'E101', errorMessage: 'Handle Facebook Omniauth missing link', errorOriginal: null };
  }

  async handleGoogleOmniAuth(link: string, studentInfo: {postalCodeId: string, phoneNumber: string}): Promise<any> {
    if (!!link) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'X-Requested-With': 'com.envoituresimone.app'
        })
      };
      const httpResponse: any = await this.http.post(link, {'postal_code_id': studentInfo.postalCodeId, 'phone': studentInfo.phoneNumber}, httpOptions).pipe(
        switchMap(
          async res => {
            if (!!res) {
              return res;
            }
            return { errorCode: 'E101', errorMessage: 'Handle Google Omniauth missing res', errorOriginal: null };
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E201', errorMessage: 'Handle Google Omniauth failed request', errorOriginal: err };
          }
      )).toPromise();
      if (!!httpResponse && !!httpResponse.errorMessage) {
        return httpResponse;
      }
      if (!!httpResponse && !!httpResponse.auth_data) {
        return { errorCode: 'E101', errorMessage: 'Handle Google Omniauth missing authUserAfterOmniauth', errorOriginal: null };
      }
      return { errorCode: 'E101', errorMessage: 'Handle Google Omniauth missing data or auth_data in httpResponse ', errorOriginal: null };
    }
    return of({ errorCode: 'E101', errorMessage: 'Handle Google Omniauth missing link', errorOriginal: null });
  }

  omniAuthErrorHandling(err: any, studentInfo: {postalCodeId: string, phoneNumber: string}): any {
    let error;
    if (!!err) {
      if (!!err.status && err.status === 422 || err.status === 0) {
        if (studentInfo.postalCodeId === null || studentInfo.phoneNumber === null) {
          error = `Postal Code and Phone Number mandatory`;
          return error;
        }
      }
      error = err;
      return error;
    }
    return { errorCode: 'E101', errorMessage: 'Omniauth Error Handling Auth Service missing err', errorOriginal: null };
  }

  async handleSocialProfil(): Promise<any> {
    // const studentData = await this.fetchProfile(this.tokenService.currentUserData.id.toString(), 'students');
    // if (!!studentData) {
    //   return studentData;
    // } else {
    //   const error = `Failed to fill profil`;
    //   return { errorCode: 'E101', errorMessage: 'Handle Social Profil Auth Service missing studentData', errorOriginal: null };
    // }
  }

  facebookLogOut(): Promise<any> {
    return this.facebook.logout();
  }

  googleLogOut(): Promise<any> {
    return this.google.logout();
  }
}
