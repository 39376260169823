import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Initialable } from './app-init.service';
import { StudentService } from './student.service';
import { StorageService } from './storage.service';

import { Student } from '../models/student';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { NetworkStatusService } from '../../app/services/network-status.service';

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init3', initializer: `init` })

@EasyDebugDecorator
export class ConfigUserService {

  tunnelTestAB = null;

  constructor(
    private platformService: Platform,
    private studentService: StudentService,
    private storageService: StorageService,
    private networkStatusService: NetworkStatusService,
  ) {
    // console.log('Config User service instanciate');
  }

  async init() {
    // console.log(`Launching Config User init`);
    const student = await this.studentService.getProfil();
    let firstName = 'Prénom';
    let lastName = 'Nom';
    let email = 'email@email.com';

    if (!!student && student.status !== 'guest') {
      firstName = student.firstName;
      lastName = student.lastName;
      email = student.email;
    }
    let config = await this.getUserConfig(student);
    // console.log(`Launching Config User init get config`, config);
    if (config === null || config === undefined) {
      config = await this.initUserConfig(student);
      // console.log(`Launching Config User init autre get config if null`, config);
    }
    // reset
    // console.log(`Launching Config User init reset`);
    if (this.studentService.studentInitLaunched) {
      config.learningContractStickyToasterShown = false;
      config.validateEmailStickyToasterShown = false;
      config.validateEmailStickyToasterClicked = false;
      config.outstandingPaymentModalShown = false;
      config.impayesStickyToasterShown = false;
      // console.log(`Launching Config User init reset set config`);
      await this.setUserConfig(student, config);
      // console.log(`Launching Config User init reset set config done`);
      this.studentService.studentInitLaunched = false;
    }
    // console.log(`Config User init done`);
    return config;
  }

  async initUserConfig(student: Student): Promise<any> {
    const config = {
      firstScreen: false,
      coursProgression: [],
      appRatingStepDone: 0,
      appRatingDone: false,
      learningContractStickyToasterShown: false,
      validateEmailStickyToasterShown: false,
      validateEmailStickyToasterClicked: false,
      outstandingPaymentModalShown: false,
      showPostRegistration: false,
      mute: true,
      modalSeries: [],
      iOSATTSliderShown: false,
      iOSATTFromRegister: false,
      iOSATTAnswered: false,
      iOSATTFirstTime: true,
      notationModalShownLastDate: null,
      pushNotifConsentActive: false
    };

    return this.setUserConfig(student, config);
  }

  async switchBoolean(student: Student, prop: string): Promise<boolean> {
    const userConfig = await this.getUserConfig(student);
    userConfig[prop] = !userConfig[prop];
    await this.setUserConfig(student, userConfig);
    return userConfig[prop];
  }

  async getUserConfig(student: Student): Promise<any> {
    if (!!student && !!student.remoteId) {
      return this.storageService.get(`${student.remoteId}-Config`);
    }
    return null;
  }

  async setUserConfig(student: Student, config: any): Promise<any> {
    if (!!student && !!student.remoteId) {
      return this.storageService.set(`${student.remoteId}-Config`, config);
    }
    return null;
  }
}
