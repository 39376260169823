import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { Directive, ElementRef, inject, Input, PLATFORM_ID } from "@angular/core";

@Directive({
    standalone: true,
    selector: '[evsAutoFocus]',
})
export class AutoFocusDirective {
    @Input('evsAutoFocus') autofocus: boolean = false;

    focused: boolean = false;

    platformId = inject(PLATFORM_ID);

    document: Document = inject(DOCUMENT);

    host: ElementRef = inject(ElementRef);

    ngAfterContentChecked() {
        // This sets the `attr.autofocus` which is different than the Input `autofocus` attribute.
        if (this.autofocus === false) {
            this.host.nativeElement.removeAttribute('autofocus');
        } else {
            this.host.nativeElement.setAttribute('autofocus', true);
        }

        if (!this.focused) {
            this.autoFocus();
        }
    }

    ngAfterViewChecked() {
        if (!this.focused) {
            this.autoFocus();
        }
    }

    autoFocus() {
        if (isPlatformBrowser(this.platformId) && this.autofocus) {
            setTimeout(() => {
                const focusableElements = AutoFocusDirective.getFocusableElements(this.host?.nativeElement);

                if (focusableElements.length === 0) {
                    this.host.nativeElement.focus();
                }
                if (focusableElements.length > 0) {
                    focusableElements[0].focus();
                }

                this.focused = true;
            });
        }
    }

    public static getFocusableElements(element, selector = ''): any[] {
        let focusableElements = this.find(element, this.getFocusableSelectorString(selector));

        let visibleFocusableElements = [];

        for (let focusableElement of focusableElements) {
            const computedStyle = getComputedStyle(focusableElement);
            if (this.isVisible(focusableElement) && computedStyle.display != 'none' && computedStyle.visibility != 'hidden') visibleFocusableElements.push(focusableElement);
        }

        return visibleFocusableElements;
    }

    public static getFocusableSelectorString(selector = ''): string {
        return `button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
        [href][clientHeight][clientWidth]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
        input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
        select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector},
        textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])${selector}`;
    }

    public static isVisible(element: HTMLElement) {
        return element && element.offsetParent != null;
    }

    public static find(element: any, selector: string): any[] {
        return Array.from(element.querySelectorAll(selector));
    }
}