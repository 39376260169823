import { ErrorHandler, Injectable } from '@angular/core';
import { ServiceLocator } from './app.services';

import { StudentService, Initialable, StatsService } from './app.services';

import { EasyDebugLogger } from './easy-debug-logger/easy-debug-logger';
import { EasyDebugDecorator } from './decorators/easy-debug.decorator';
import { NetworkStatusService } from './services/network-status.service';

import * as Sentry from '@sentry/angular-ivy';

@EasyDebugDecorator
@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init3', initializer: `init` })
export class AppErrorHandler implements ErrorHandler {

  // ###############################################
  // # AppErrorHandler IS USED TO CATCH ALL ERRORS #
  // ###############################################

  private studentService: StudentService;
  private networkStatusService: NetworkStatusService;
  private statsService: StatsService;
  private initDone = false;
  private easyDebugLogger = EasyDebugLogger.getInstance();

  constructor(
  ) {}

  async init() {
    // console.log('AppErrorHandler init');
    // ErrorHandler are call at the very very very beginning of the stack therefor the
    // ServiceLocator used in appt-init is not yet defined... conclusion: I cant add student service in
    // constructor.... Sad.... Doing it manualy then
    this.studentService = ServiceLocator.injector.get(StudentService);
    this.statsService = ServiceLocator.injector.get(StatsService);
    this.networkStatusService = ServiceLocator.injector.get(NetworkStatusService);
    this.initDone = true;
    // console.log('AppErrorHandler init done');
    return 'AppErrorHandler done';
  }

  handleError(error) {
    this.easyDebugLogger.logError('Error', error.message);
    if (!this.networkStatusService?.isOffline()) {
      // console.log('error => ', error);
      if (this.initDone) {
        this.useAdvancedHandler(error);
      } else {
        this.useBasicHandler(error);
      }
    }
  }

  async useAdvancedHandler(error: any) {
    // console.log(error);
    // console.log('app error handler =>', error);
    const student = !!this.studentService ? this.studentService.student : null;
    const statsContext = (!!student) ? student.status !== 'guest' ? await this.statsService.processUser() : null : null;
    const context = { id: !!student ? student.remoteId : 'guest', ...statsContext };
    this.sendToSentry(error, context);
  }

  useBasicHandler(error) {
    this.sendToSentry(error, { id: 'guest' });
  }

  sendToSentry(error: any, context: any) {
    console.error('send to sentry error handler =>', error);
    Sentry.withScope(function (scope: any) {
      scope.setUser(context);
      Sentry.captureException(error.originalError || error);
    });
  }
}
